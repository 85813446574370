import styles from "../../styles/Login.module.css";
import toast from "react-hot-toast";
import { Form, Formik, Field } from "formik";
import { Toaster } from "react-hot-toast";
import { usePasswordAuth } from "../../hooks/auth";
import { useState } from "react";

interface LoginValues {
  email: string;
  password: string;
}

function validateLoginForm(values: LoginValues) {
  return values.email !== "" && values.password !== "";
}

function showErrors(message: string) {
  // https://react-hot-toast.com/docs/toast
  toast.error(message, {
    style: {
      minWidth: "430px",
    },
  });
}

const PasswordAuth = () => {
  const [loading, setLoading] = useState(false);
  const passwordAuth = usePasswordAuth({
    middleware: "guest",
    redirectIfAuthenticated: "/home",
  });

  const submit = (values: LoginValues): void => {
    if (!validateLoginForm(values)) {
      return showErrors("メールアドレスとパスワードを入力してください");
    }

    setLoading(true);
    passwordAuth
      .login({ ...values })
      .then(() => {
        toast.success("ログインしました");
      })
      .catch(() => {
        showErrors("メールアドレスまたはパスワードが間違っています");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const initialValues: LoginValues = {
    email: "",
    password: "",
  };

  return (
    <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={(values: LoginValues) => submit(values)}>
      {() => (
        <Form>
          <Toaster position="top-center" />
          <div>
            <Field placeholder="メールアドレス" name="email" type="text" className={styles.emailInput} />
          </div>
          <div>
            <Field placeholder="パスワード" name="password" type="password" className={styles.passwordInput} />
          </div>
          <button type="submit" className={styles.loginButton} disabled={loading}>
            ログイン
          </button>
        </Form>
      )}
    </Formik>
  );
};

export { PasswordAuth };
