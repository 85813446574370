import type { NextPage } from "next";
import Image from "next/image";
import { FirebaseAuth } from "../components/Login/FirebaseAuth";
import { PasswordAuth } from "../components/Login/PasswordAuth";
import styles from "../styles/Login.module.css";
import Link from "next/link";
import { clsx } from "clsx";

const Login: NextPage = () => {
  return (
    <section className={clsx("h-screen", styles.loginSection)}>
      <div className="px-6 py-12 h-full">
        <div className="flex justify-center items-center flex-wrap h-full g-6 text-gray-800">
          <div className={clsx("md:w-8/12 lg:w-5/12 text-center p-8 md:p-8 lg:p-24", styles.loginFormBox)}>
            <Image src="/logo.png" alt="Swish Logo" width={194} height={58} />
            <h3 className="text-primarydark">ログイン</h3>
            <PasswordAuth />
            <Link href="/password_reset">
              <a className="text-sm text-primary cursor-pointer">パスワード変更</a>
            </Link>
            <hr className="my-3" />
            <div className="text-sm text-gray-500">または外部サービスでログインする</div>
            <FirebaseAuth />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
